import ItemActions from '@wix/photography-client-lib/dist/src/item/itemActions';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';

export default class ItemsHelper {
  constructor(galleryWrapper, props, isStoreGallery) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;
    this.isStoreGallery = isStoreGallery;
    this.update = this.update.bind(this);
    this.initItemActions = this.initItemActions.bind(this);
    this.getItemActions = this.getItemActions.bind(this);
    this.onLoveCountsFetched = this.onLoveCountsFetched.bind(this);
    this.updateLocalLoveData = this.updateLocalLoveData.bind(this);
    this.pgItemsProps = this.pgItemsProps.bind(this);
    this.areOneOrMoreItemsCorrupted = this.areOneOrMoreItemsCorrupted.bind(
      this,
    );
    this.getMoreItems = this.getMoreItems.bind(this);
    this.onItemActionTriggered = this.onItemActionTriggered.bind(this);
    this.onItemClicked = this.onItemClicked.bind(this);
    this.onCurrentItemChanged = this.onCurrentItemChanged.bind(this);
    this.onLinkNavigation = this.onLinkNavigation.bind(this);
    this.handleItemActions = this.handleItemActions.bind(this);

    this.itemActions = new ItemActions();
    this.initItemActions();
  }

  update(props) {
    this.galleryWrapperProps = props;
  }

  initItemActions() {
    this.itemActions.initWidgetData({
      compId: this.galleryWrapperProps.id,
      pageId: this.galleryWrapperProps.pageId,
      styleId: this.galleryWrapperProps.styleId,
      galleryId: this.galleryWrapperProps.galleryId,
      isStoreGallery: this.isStoreGallery,
      baseUrl: this.galleryWrapperProps.baseUrl,
      pageUrl: this.galleryWrapperProps.pageUrl,
      fullscreenUrl: this.galleryWrapperProps.fullscreenUrl,
      instanceId: this.galleryWrapperProps.instanceId,
      onLoveCountsFetched: this.onLoveCountsFetched,
    });
  }

  getItemActions() {
    return this.itemActions;
  }

  onLoveCountsFetched(photoId2Love) {
    const itemsLoveData = {};
    Object.entries(photoId2Love).forEach(([id, loveCount]) => {
      itemsLoveData[id] = {
        loveCount,
        isLoved: this.itemActions.isLoved(id),
      };
    });
    this.galleryWrapper.setState({ itemsLoveData });
  }

  updateLocalLoveData(id) {
    const itemsLoveData = this.galleryWrapper.state.itemsLoveData;
    let updatedItemLoveData;
    if (itemsLoveData[id]) {
      updatedItemLoveData = {
        isLoved: !itemsLoveData[id].isLoved,
        loveCount: itemsLoveData[id].isLoved
          ? itemsLoveData[id].loveCount - 1
          : itemsLoveData[id].loveCount + 1,
      };
    } else {
      updatedItemLoveData = {
        isLoved: true,
        loveCount: 1,
      };
    }
    const updatedItemsLoveData = Object.assign({}, itemsLoveData, {
      [id]: updatedItemLoveData,
    });
    this.galleryWrapper.setState({ itemsLoveData: updatedItemsLoveData });
  }

  pgItemsProps() {
    let items;

    if (!this.galleryWrapperProps.wixCodeItems) {
      items =
        this.galleryWrapper.state.manualItems ||
        this.galleryWrapperProps.items ||
        [];
    } else {
      items = this.galleryWrapperProps.wixCodeItems.map(item => {
        if (typeof item.metaData === 'string') {
          try {
            const newItem = {
              ...item,
              metaData: JSON.parse(item.metaData),
            };
            return newItem;
          } catch (e) {
            console.error('Failed parse item metaData', e);
          }
        }
        return item;
      });
    }
    const totalItemsCount =
      this.galleryWrapperProps.totalItemsCount || items.length || 1;

    return {
      items,
      totalItemsCount,
    };
  }

  areOneOrMoreItemsCorrupted(items) {
    return items.some(this.isInvalidItem);
  }

  isInvalidItem(item) {
    //for future validations add more conditions
    const containsItemId = item.itemId === undefined;
    return containsItemId;
  }

  getMoreItems(from) {
    if (this.galleryWrapperProps.getMoreItems) {
      this.galleryWrapperProps.getMoreItems(from);
    }
  }

  onItemActionTriggered(itemProps, styleParams) {
    const itemClick = styleParams.itemClick;
    const itemClickProps = {
      dto: this.pgItemsProps().items.find(item => item.itemId === itemProps.id),
      id: itemProps.id,
      idx: itemProps.idx,
    };
    this.onItemClicked(itemClickProps, itemClick);
    if (itemClick === 'fullscreen') {
      this.galleryWrapper.fullscreenHelper.toggleBrowserFullscreen();
      this.galleryWrapper.fullscreenHelper.animatedOpenFullscreen(
        itemClickProps,
      );
    } else if (itemClick === 'expand') {
      this.galleryWrapper.fullscreenHelper.animatedOpenFullscreen(
        itemClickProps,
      );
    }
  }

  onItemClicked(itemClickProps, itemClick) {
    if (typeof this.galleryWrapperProps.onItemClicked === 'function') {
      this.galleryWrapperProps.onItemClicked(itemClickProps, itemClick);
    }
  }

  onCurrentItemChanged(item) {
    if (typeof this.galleryWrapperProps.onCurrentItemChanged === 'function') {
      this.galleryWrapperProps.onCurrentItemChanged(item);
    }
  }

  onLinkNavigation(item) {
    const { linkData } = item;
    if (!linkData || !linkData.type) {
      console.error(
        'link navigation failed due to invalid link data',
        linkData,
      );
      return;
    }

    switch (linkData.type) {
      case 'ExternalLink':
        window.open(linkData.url, linkData.target);
        break;
      default:
        const itemForNavigation = { dto: item.dto };
        delete itemForNavigation.resizeMediaUrl;
        this.galleryWrapperProps.onLinkNavigation(itemForNavigation);
    }
  }

  handleItemActions(action, actionData) {
    let item;
    switch (action) {
      case 'share':
        this.itemActions.share(actionData.network, actionData.shareProps);
        break;
      case 'downloadTextItem':
        item = {
          html: actionData.html,
          style: actionData.style,
        };
        this.itemActions.downloadTextItem(item);
        break;
      case 'postLoveActivity':
        item = {
          type: actionData.type,
          itemId: actionData.itemId,
          id: actionData.id,
          idx: actionData.idx,
          hashtag: actionData.hashtag,
        };
        this.itemActions.postLoveActivity(item);
        break;
      case 'toggleLove':
        this.itemActions.toggleLove(actionData.id);
        break;
      default:
        break;
    }
  }
}
