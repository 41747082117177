import React from 'react';
import _ from 'lodash';
import ProGalleryFullscreenMock from '../components/ProGallery/FullscreenWrapper/ProGalleryFullscreenMock';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import '../styles/WixStyles.scss';
import VIEW_MODE from 'pro-gallery/dist/src/common/constants/viewMode';

export default class CommonFullscreenWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      container: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      proFullscreenLoaded: false,
    };

    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.resize = this.resize.bind(this);
    this.debouncedResizeEvent = _.debounce(this.resize, 100);
    this.lastPageScroll = -1;
    this.FullscreenElement = null;
  }

  componentWillMount() {
    window.addEventListener('fullscreenchange', this.onFullscreenChange);
    window.addEventListener('webkitfullscreenchange', this.onFullscreenChange);
    window.addEventListener('mozfullscreenchange', this.onFullscreenChange);
    window.addEventListener('MSFullscreenChange', this.onFullscreenChange);
    window.addEventListener('resize', this.debouncedResizeEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('fullscreenchange', this.onFullscreenChange);
    window.removeEventListener(
      'webkitfullscreenchange',
      this.onFullscreenChange,
    );
    window.removeEventListener('mozfullscreenchange', this.onFullscreenChange);
    window.removeEventListener('MSFullscreenChange', this.onFullscreenChange);
    window.removeEventListener('resize', this.debouncedResizeEvent);
  }

  componentDidMount() {
    import(
      /* webpackChunkName: "pro-fullscreen-renderer" */ '@wix/pro-fullscreen-renderer'
    ).then(module => {
      this.FullscreenElement = module.ProFullscreen;
      this.setState({ proFullscreenLoaded: true });
    });
  }

  resize() {
    this.setState({
      container: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  onFullscreenChange = () => {
    let container = {
      width: window.screen.width,
      height: window.screen.height,
    };

    if (
      !window.document.fullscreenElement &&
      !window.document.webkitIsFullScreen &&
      !window.document.mozFullScreen &&
      !window.document.msFullscreenElement
    ) {
      container = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }

    this.setState({ container });
  };

  blockParentScroll(shouldBlock) {
    const stopScrollClass = 'pro-gallery-stop-scroll-for-fullscreen';
    const pageHtml = window.document.getElementsByTagName('html')[0];
    const classList = pageHtml && pageHtml.classList;

    try {
      if (shouldBlock && !this.parentScrollIsBlocked) {
        this.lastPageScroll = window.scrollY;
        this.parentScrollIsBlocked = true;
        classList.add(stopScrollClass);
      } else if (!shouldBlock && this.parentScrollIsBlocked) {
        this.parentScrollIsBlocked = false;
        classList.remove(stopScrollClass);
        if (this.lastPageScroll >= 0) {
          this.lastPageScroll = -1;
          window.scrollTo(0, this.lastPageScroll);
        }
      }
    } catch (e) {
      console.log('Cannot stop parent scroll', e);
    }
  }

  getFullscreenElement() {
    return this.state.proFullscreenLoaded && this.FullscreenElement
      ? this.FullscreenElement
      : null;
  }

  additionalProFullscreenProps() {
    return {};
  }

  canRender() {
    return this.props.fullscreenIdx >= 0;
  }

  getRenderElement() {
    if (!this.canRender()) {
      return null;
    }
    const ProFullscreenElement = this.getFullscreenElement();
    const props = this.props;
    if (ProFullscreenElement) {
      return (
        <ProFullscreenElement
          {...this.additionalProFullscreenProps()}
          items={props.items}
          initialIdx={props.fullscreenIdx}
          totalItemsCount={props.totalItemsCount}
          container={this.state.container}
          locale={props.locale}
          styles={props.styleParams}
          galleryId={props.galleryId}
          viewMode={props.viewMode}
          noFollowForSEO={props.noFollowForSEO}
          eventsListener={props.eventsListener}
          itemsLoveData={props.itemsLoveData}
        />
      );
    } else if (props.viewMode === VIEW_MODE.SEO) {
      return (
        <ProGalleryFullscreenMock
          items={props.items}
          totalItemsCount={props.totalItemsCount}
          container={this.state.container}
          locale={'en'}
          galleryId={props.galleryId}
          viewMode={props.viewMode}
          noFollowForSEO={props.noFollowForSEO}
          eventsListener={props.eventsListener}
          itemsLoveData={props.itemsLoveData}
          resizeMediaUrl={props.resizeMediaUrl}
          domId={props.domId}
          allowSSR={true}
        />
      );
    } else {
      return null;
    }
  }

  getStyleForWrapper() {
    return {
      opacity: this.props.fullscreenAnimating ? 0 : 1,
      transition: 'opacity .8s ease',
      overflow: 'hidden',
    };
  }

  render() {
    const renderElement = this.getRenderElement();
    return (
      renderElement && (
        <div
          className="pro-fullscreen-wrapper"
          style={this.getStyleForWrapper()}
        >
          {renderElement}
        </div>
      )
    );
  }
}
