import CommonFullscreenWrapper from '../../../common/CommonFullscreenWrapper';
import './FullscreenWrapper.scss';

export default class ProFullscreenWrapper extends CommonFullscreenWrapper {
  getFullscreenElement() {
    return this.state.proFullscreenLoaded && this.FullscreenElement
      ? this.FullscreenElement
      : null;
  }
}
