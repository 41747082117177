import RenderUtils from '@wix/photography-client-lib/dist/src/utils/renderUtils';

class Utils extends RenderUtils {
  /* @ngInject */
  constructor() {
    super();
  }
}

export const utils = new Utils();
