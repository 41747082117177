import { utils } from '../../utils';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import ResizeObserver from 'resize-observer-polyfill';

export default class DimensionsHelper {
  constructor(parent, props) {
    this.container = {};
    this.domId = props.id || '';
    this._cache = {};
    this.lastPropsJson = '';
    this.parent = parent;

    this.update(props);
  }

  getOrPutInCache(field, createValue) {
    if (this._cache[field]) return this._cache[field];
    this._cache[field] = createValue();
    return this._cache[field];
  }

  dumpCache(field) {
    if (field) {
      this._cache[field] = undefined;
    } else {
      this._cache = {};
    }
  }

  update(props) {
    const { dimensions, id } = props;
    const propsJson = JSON.stringify({ dimensions, id });
    if (this.lastPropsJson !== propsJson) {
      this.lastPropsJson = propsJson;
      this.domId = id || this.domId;
      this.dimensions = { ...this.dimensions, ...dimensions };
      this.measureContainer();
    }
    this.observeResize();
    this.observeIntersection();
  }

  createResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => {
      this.measureContainer();
    });
    this.observeResize();
  }

  observeResize() {
    if (this.observingResize || !this.resizeObserver) {
      return;
    }
    const galleryWrapperElement = window.document.getElementById(
      `gallery-wrapper-${this.domId}`,
    );
    if (galleryWrapperElement) {
      this.observingResize = true;
      this.resizeObserver.observe(galleryWrapperElement);
    }
  }

  createIntersectionObserver() {
    if (utils.isSSR()) {
      return;
    }
    //import of intersection-observer-polyfill breaks SSR!!!

    import(
      /* webpackChunkName: "intersection-observer-polyfill" */ 'intersection-observer-polyfill/src/IntersectionObserver'
    ).then(module => {
      this.IntersectionObserverPolyfill = module.default;
      let IntersectionObserver;
      // Define what implementation of IntersectionObserver
      // needs to be exported: existing or polyfilled.
      if (typeof window.IntersectionObserver === 'function') {
        // Export existing IntersectionObservers' implementation.
        IntersectionObserver = window.IntersectionObserver;
      } else {
        // Export polyfill.
        IntersectionObserver = this.IntersectionObserverPolyfill;
      }
      this.intersectionObserver = new IntersectionObserver(entries => {
        const entry = entries.length > 0 && entries[0];
        if (entry && entry.boundingClientRect) {
          const newWrapperBoundingRectY = entry.boundingClientRect.y;
          if (this.wrapperBoundingRectY !== newWrapperBoundingRectY) {
            this.measureScrollBase(newWrapperBoundingRectY);
          }
        }
      });
      this.observeIntersection();
    });
  }

  observeIntersection() {
    if (this.observingIntersection || !this.intersectionObserver) {
      return;
    }
    try {
      const galleryWrapperElement = window.document.getElementById(
        `gallery-wrapper-${this.domId}`,
      );
      if (galleryWrapperElement) {
        this.observingIntersection = true;
        this.intersectionObserver.observe(galleryWrapperElement);
      }
    } catch (e) {
      console.error('dimensionsHelper -> observeIntersection,', e);
    }
  }

  measureContainer() {
    this.dumpCache();
    this.container =
      this.fixContainerWidthIfNeeded(this.dimensions) || this.container;
    const container = {
      ...this.getGalleryDimensions(),
      ...this.getScrollBase(),
    };

    if (
      JSON.stringify(container) !== JSON.stringify(this.parent.state.container)
    ) {
      this.parent.setState({ container });
    }
  }

  measureScrollBase(newWrapperBoundingRectY) {
    this.dumpCache('scrollBase');
    this.dumpCache('bodyBoundingRect');
    const scrollBase = this.calcScrollBase(newWrapperBoundingRectY);
    if (
      JSON.stringify(scrollBase) !==
      JSON.stringify(this.parent.state.container.scrollBase)
    ) {
      const container = Object.assign({}, this.parent.state.container, {
        scrollBase: scrollBase,
      });
      this.parent.setState({ container });
    }
  }

  getGalleryDimensions() {
    return this.getOrPutInCache('galleryDimensions', () => {
      const res = {
        avoidGallerySelfMeasure: this.parent.avoidGallerySelfMeasure,
        galleryWidth: Math.ceil(this.getGalleryWidth()),
        galleryHeight: Math.ceil(this.getGalleryHeight()),
        height: Math.ceil(this.container.height),
        width: Math.ceil(this.container.width),
        documentHeight: Math.ceil(window.document.body.scrollHeight),
        windowWidth: Math.ceil(window.innerWidth),
      };
      return res;
    });
  }

  getScrollBase() {
    return {
      scrollBase: Math.ceil(this.calcScrollBase()),
    };
  }

  isUnknownWidth(container = this.container) {
    return this.getOrPutInCache('isUnknownWidth', () => {
      //if the container width is not a number, it is fullwidth (e.g.: "", "100%", "calc(100% + -160px)")
      return (
        container &&
        String(parseInt(container.width)) !== String(container.width)
      );
    });
  }

  fixContainerWidthIfNeeded(dimensions) {
    const isUnknownWidth = this.isUnknownWidth(dimensions);
    if (isUnknownWidth) {
      const _dimensions = { ...dimensions };
      _dimensions.width = this.getBoundingRect().width;
      return _dimensions;
    } else {
      return dimensions;
    }
  }

  calcBoundingRect() {
    if (utils.isVerbose()) {
      console.count('calcBoundingRect');
    }
    try {
      this.galleryWrapperElement = window.document.getElementById(
        `gallery-wrapper-${this.domId}`,
      );
      return this.galleryWrapperElement.getBoundingClientRect();
    } catch (e) {
      return false;
    }
  }

  getBoundingRect() {
    return this.getOrPutInCache('boundingRect', () => {
      return (
        this.calcBoundingRect() || {
          x: 0,
          y: 0,
          width: Math.ceil(window.innerWidth),
          height: Math.ceil(window.innerHeight),
        }
      );
    });
  }

  getBodyBoundingRect() {
    return this.getOrPutInCache('bodyBoundingRect', () => {
      return (
        this.calcBodyBoundingRect() || {
          x: 0,
          y: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      );
    });
  }

  calcBodyBoundingRect() {
    if (utils.isVerbose()) {
      console.count('calcBodyBoundingRect');
    }
    try {
      const popupRootElement = window.document.getElementById('POPUPS_ROOT');
      if (!this.galleryWrapperElement) {
        this.galleryWrapperElement = window.document.getElementById(
          `gallery-wrapper-${this.domId}`,
        );
      }
      if (
        popupRootElement &&
        this.galleryWrapperElement &&
        popupRootElement.contains(this.galleryWrapperElement)
      ) {
        return popupRootElement.getBoundingClientRect();
      } else {
        return window.document.body.getBoundingClientRect();
      }
    } catch {
      return false;
    }
  }

  calcScrollBase(newWrapperBoundingRectY) {
    return this.getOrPutInCache('scrollBase', () => {
      let { scrollBase } = this.container;
      try {
        if (!(scrollBase >= 0)) {
          scrollBase = 0;
        }
        this.wrapperBoundingRectY =
          newWrapperBoundingRectY === undefined
            ? this.getBoundingRect().y
            : newWrapperBoundingRectY;
        const offset = this.wrapperBoundingRectY - this.getBodyBoundingRect().y; //clientRect are relative to the viewport, thus affected by scroll and need to be normalized to the body
        if (offset >= 0) {
          scrollBase += offset;
        }
      } catch (e) {
        //
      }
      return Math.ceil(scrollBase);
    });
  }

  getGalleryWidth() {
    return this.getOrPutInCache('galleryWidth', () => {
      const domWidth = () =>
        utils.isSSR() ? '' : Math.ceil(this.getBoundingRect().width);
      return this.container.width > 0
        ? Math.ceil(this.container.width)
        : domWidth();
    });
  }

  getGalleryHeight() {
    return this.getOrPutInCache('galleryHeight', () => {
      const domHeight = () =>
        utils.isSSR() ? '' : Math.ceil(this.getBoundingRect().height);
      return this.container.height > 0
        ? Math.ceil(this.container.height)
        : domHeight();
    });
  }
}
